<template>
  <div class="loading" v-if="LoadingStatus">
    <b-container>
      <div class="loading-eggsbook">
        <img src="~@/assets/images/icons/loading.svg" alt="" />
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  data() {
    return {
      isLoading: true,
      fullPage: true,
    };
  },
  computed: {
    ...mapGetters({
      LoadingStatus: 'LoadingStatus',
    }),
    ...mapState({
      totalSend: 'totalSend',
      totalReceive: 'totalReceive',
    }),
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba($color: #000000, $alpha: 0.7);
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
  }
}
</style>
